import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";
export default {
  data() {
    return {
      validate: [
        {
          formId: 1,
          disposalSiteName: { isValid: true, errMsg: "" },
          zipCode: { isValid: true, errMsg: "" },
          prefecturesId: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          buildingName: { isValid: true, errMsg: "" },
          tel: { isValid: true, errMsg: "" },
          fax: { isValid: true, errMsg: "" },
          jwnetContractNo: { isValid: true, errMsg: "" },
          ediKey: { isValid: true, errMsg: "" },
          disposalStoreCode: { isValid: true, errMsg: "" },
          finalDisposalSiteList: [],
        },
      ],
    };
  },
  methods: {
    getTargetValidate(formId) {
      return this.validate.filter((v) => v.formId === formId)[0];
    },

    async check() {
      let isValidForm = true;
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      for (let i = 0; i < this.validate.length; i++) {
        const v = this.validate[i];
        const targetForm = this.form.filter((f) => f.formId === v.formId)[0];
        // 処分場名の禁則文字のチェック
        if (PROHIBITED_CHARACTERS_REGEX.test(targetForm.disposalSiteName)) {
          v.disposalSiteName.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
        if (!v.disposalSiteName.isValid) {
          v.disposalSiteName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.zipCode.isValid) {
          v.zipCode.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${targetForm.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.prefecturesId.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (targetForm.addressInfo.selectedPrefectureTemp === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    v.prefecturesId.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (targetForm.addressInfo.address === address.address2) {
                      addressFlg = true;
                    }
                  });
                  if (!addressFlg) {
                    v.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (!v.prefecturesId.isValid) {
          v.prefecturesId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.address.isValid) {
          v.address.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetForm.addressInfo.buildingName && !v.buildingName.isValid) {
          v.buildingName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.tel.isValid) {
          v.tel.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetForm.fax && !v.fax.isValid) {
          v.fax.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.jwnetContractNo.isValid) {
          v.jwnetContractNo.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(targetForm.jwnetContractNo)) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は半角英数字で入力してください。";
          isValidForm = false;
        }
        if (!v.ediKey.isValid) {
          v.ediKey.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(targetForm.ediKey)) {
          v.ediKey.errMsg = "EDI利用確認キーは半角英数字で入力してください。";
          isValidForm = false;
        }
        if (!v.disposalStoreCode.isValid) {
          v.disposalStoreCode.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        // 最終処分場の入力チェック
        const isfinalDisposalSiteValidateCheck = await this.finalDisposalSiteValidateCheck(v, targetForm);
        if (!isfinalDisposalSiteValidateCheck) {
          isValidForm = false;
        }
      }

      return isValidForm;
    },

    async finalDisposalSiteValidateCheck(validate, form) {
      let isValidForm = true;
      if (!validate.finalDisposalSiteList) return;
      for (let index = 0; index < validate.finalDisposalSiteList.length; index++) {
        const targetValidate = validate.finalDisposalSiteList[index];
        const targetDisposalSiteForm = form.finalDisposalSiteList[index];
        // 最終処分場名の禁則文字のチェック
        if (PROHIBITED_CHARACTERS_REGEX.test(targetDisposalSiteForm.finalDisposalSiteName)) {
          targetValidate.finalDisposalSiteName.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
        if (!targetValidate.finalDisposalSiteName.isValid) {
          targetValidate.finalDisposalSiteName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!targetValidate.zipCode.isValid) {
          targetValidate.zipCode.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${targetDisposalSiteForm.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (targetValidate.prefecture.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (targetDisposalSiteForm.addressInfo.selectedPrefectureTemp === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    targetValidate.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (targetValidate.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (targetDisposalSiteForm.addressInfo.address === address.address2) {
                      addressFlg = true;
                    }
                  });
                  if (!addressFlg) {
                    targetValidate.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (!targetValidate.prefecture.isValid) {
          targetValidate.prefecture.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!targetValidate.address.isValid) {
          targetValidate.address.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetDisposalSiteForm.addressInfo.buildingName && !targetValidate.building.isValid) {
          targetValidate.building.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetDisposalSiteForm.tel && !targetValidate.tel.isValid) {
          targetValidate.tel.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetDisposalSiteForm.finalDisposalStoreCode){
          if (!targetValidate.finalDisposalStoreCode.isValid) {
            targetValidate.finalDisposalStoreCode.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
        }
      }
      return isValidForm;
    },

    async runValidate() {
      let isValidForm = false;
      isValidForm = await this.check();

      return isValidForm;
    },
  },
};
